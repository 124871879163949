* {
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px;

}
::-webkit-scrollbar-track {
  background: #f1f1f1;
 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000; 
}

@font-face {
  font-family: "FontLight";
  src: url("./fonts/Akkurat\ Light.ttf") format("truetype");
}
@font-face {
  font-family: "FontDark";
  src: url("./fonts/Akkurat\ Bold.ttf") format("truetype");
}
$font-mainLight: "FontLight", sans-serif;
$font-mainDark: "FontDark", sans-serif;
body {
  font-family: $font-mainLight;
}

nav{
  font-family: $font-mainDark;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ORDERSUMMARY {
  margin-top: 1rem;
}

.logoutSureButton{
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.cart-quan {
  // position: absolute;
  // border: 1px solid red;
  // border-radius: 50%;
  // padding: 2px;
  // background-color: #111;
  // color: #fff;
  margin-left: 8px;
  font-size: 16px;
  border: 1px solid rgb(51,51,51);
  border-radius: 50%;
  padding: 4px 6px;
}

.categories .col-md-4 {
  display: block;
  text-align: center;
  transition: transform 10s;
  padding: 15px;
}

// .categories .col-md-4:hover img {
//   transform: scale(1.1);
// }

.categories .col-md-4 img {
  width: 200px;
  height: 200px;
  margin: 10px 0;
}

.small-image {
  width: 20% !important;
  height: 20% !important;
  display: block;
}

.btn-small {
  width: 70px;
  text-align: center;
  outline: none;
}

.orderreview table thead,
.stockrepot table thead {
  background-color: #111;
  color: #fff;
}

.small-logo {
  width: 40px !important;
  height: 40px !important;
}

.medium-img {
  width: 60px !important;
  height: 60px !important;
  /* margin: 5px; */
}

.img-container {
  display: flex !important;
  justify-content: center;
  align-content: center;
  align-items: center;
}

nav img {
  width: 70px;
  height: 70px;
}

// .footer-margin {
//   margin: 333px !important;
// }
.spinner {
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 color: #333 !important;
}
.spinnerDiv {
  width: 100vw;
  height: 100vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  position: fixed;
  background-color: rgba(119, 128, 138, 0.2);
  backdrop-filter: blur(1px);
}

.admin-header,
.login-header,
.admin-dashboard-header {
  background-color: #333;
  color: #fff;
  box-shadow: -10px 10px 10px #555;
  margin-bottom: 100px;
}

.payment-input {
  font-size: 40px !important;
}

.pagenotfound {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 75vh;
  transform: translate(0, 10%);
}
.pagenotfound h3 {
  font-size: 3rem;
  font-weight: bold;
}
.pagenotfound h1 {
  font-weight: bold;
  font-size: 5rem;
}
.pagenotfound p {
  font-weight: bold;
}
.pagenotfound .btn {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

.inactiveClient {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 95vh;
  flex-direction: column;
  align-items: center;
}
.inactiveClient h1 {
  text-shadow: -2px -2px 10px #333, 2px 2px 10px #333;
}

.table-image {
  width: 50px !important;
  height: 50px !important;
  display: block;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"].product-quan::-webkit-inner-spin-button {
  -webkit-appearance: auto;
}

.payment-error {
  border: 1px solid red;
  border-radius: 5px;
}
.table-show-limit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.show-limit {
  display: inline-block;
}
.show-limit-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.spinner-table {
  width: 100px !important;
  height: 100px !important;

  /* transform: translate(-50%,-50%); */
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #333;
  border-color: #333;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #333;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #333;
  border-color: #333;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #333;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media (max-width: 570px) {
  table tr {
    font-size: 10px;
  }

  .small-logo {
    margin-top: -10px;
  }

  .btn-small {
    width: 50px;
    font-size: 8px;
    padding: 0;
  }
  .table-show-limit {
    display: block;
    /* justify-content: space-between;
        align-items: center; */
  }
}

.wtow {
  width: 350px;
}

.wone {
  width: 720px;
}

.in-w {
  width: 70px;
  font-weight: bold;
}

.sel-w {
  // width: 70px;
  font-size: 15px;
  padding: 0;
  width: 120px;

}

.comm-w {
  width: 150px;
  font-size: 15px;
}

.s-i {
  border: 1px solid #333;
  border-radius: 5px;
}

.logo-h3 {
  font-weight: bold;
  margin-top: 5px;
}
.sort-table-btn {
  background-color: transparent;
  border: transparent;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}
@media (max-width: 1000px) {
  .wtow {
    width: 350px;
  }

  .wone {
    width: 500px;
  }

  .detail-btn,
  .add-btn {
    font-size: 10px;
    margin-top: 3px;
  }
}



@media (max-width: 770px) {
  .wtow {
    width: 100%;
  }

  .wone {
    width: 100%;
  }

  .in-w {
    width: 40px;
    text-align: left;
    font-size: 10px;
    padding-right: 0;
  }

  .sel-w {
    width: 50px;
    font-size: 10px;
    padding: 0;
    height: 30px !important;
  }

  .comm-w {
    width: 70px;
    font-size: 10px;
  }
}



// sass styling

$footer-background-color: rgb(51, 51, 51);
$footer-text-clor: rgb(255, 255, 255);
$label-text-color:#fff;
$label-background-color-stroke:rgb(255, 255, 255);
$label-background-color-fill:rgba(0, 0, 0,0.7);
$label-radius:5px;
$button-background-color-stroke:rgb(255, 255, 255);
$button-background-color-fill:rgba(0, 0, 0,0.8);
$detialButton-background-color-stroke:rgb(229, 229, 228);
$detialButton-background-color-fill:rgba(0, 0, 0,0.8);
$button-radius:5px;
$button-shadow:0px 5px 10px black;
$card-header-background-color:rgb(51,51,51);
$table-header-background-color:rgb(51, 51, 51);
$table-header-color:#fff;
footer.clientFooter {
  div {
    text-align: right;
    padding: 14px 20px;
    background-color: $footer-background-color;
    color: $footer-text-clor;
    font-size: 15px;

    img {
      width: 20px !important;
      height: 20px !important;
      margin-top: -2px;
    }
  }
}
.landing{
  overflow-x: hidden;
  position: relative;
  .landingImage{
    // width: 100%;
    // height: 85vh;
    width:100%;
    height: 740px;
    opacity: 0.8;
    z-index: -1;
  }
  .loginPage{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
  }.landing-text{
    margin-top: 20px;
  }
}
.label,.col-form-label{
  background-color: $label-background-color-fill;
  color: $label-background-color-stroke;
  border-radius: $label-radius;
  margin-bottom: 4px;
}
.button,.btn-info,.btn-dark,.btn-success,.btn-secondary,.btn-primary,.btn-danger{
  background-color:$button-background-color-fill;
  color: $button-background-color-stroke;
  border-radius: $button-radius;
  box-shadow:$button-shadow;
  border-color: #000 !important;
  &:hover
  {
    background-color:$button-background-color-stroke;
    color: $button-background-color-fill;
    border: 1px solid #000;
    transition: 0.5s;
  }
}.detail-btn{
  background-color:rgb(229, 229, 228);
  color: $detialButton-background-color-fill;
  border: transparent !important;
}
.disabledBtn{
  &:hover{
    background-color:$button-background-color-fill;
    color: $button-background-color-stroke;
    border: 1px solid #000;
  }
}
input[type="text"],input[type="password"],input[type="email"],input[type="number"],input[type="date"],textarea{
  border: 1px solid #000;
  border-radius: 5px;
}
.loginButtonOption{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .buttons{
    display: flex;
    flex-direction: column;
    justify-content: right;
  }
}
.clientDashboard{
  position: relative;
  overflow-x: hidden;
  .dashboardImage{
    width: 100%;
    height: 84.7vh !important;
    // min-height: calc(90.6vh - 52px) !important;

    opacity: 0.8;
    z-index: -1;
  }
  .dashboard-actions{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
  }
}

.clientCardHeader{
  background-color: $card-header-background-color;
}
.singleCategory{
  img{
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background-color:rgb(242, 242, 242);
  align-items: center;
  padding: 30px;
  }

  button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
}
// .product{
//   // margin-top: px;
//   // .productHeader{
//   //   margin-bottom: 50px;
//   // }
// }
.App {
  main{
    // min-height: calc(90.6vh - 52px);
    min-height: calc(93vh + 2px)!important;
    
  }
}

.addQuantity,.addComment{
  color: rgb(51, 51, 51);
  background-color: rgb(242, 242, 242);
}
.unitSelect{
  color: rgb(51, 51, 51);

}
.selectList {
  border: 1px solid #000 !important;
  color: rgb(51, 51, 51) !important;
  border-radius: 5px;

}

.bg-light {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
nav{
  height: 80px !important;
  .navbarImage{
    width:150px;
    height:80px;
  }
}
.navbar-light{
  background-color: transparent;
}
.cartLoginForm,.stripeForm{
  label{
    width: 100% !important;
    padding: 10px 10px 10px 4px;

  }
}
// .absoluteNavbarOnLanding{
//   position: absolute;
//   z-index: 2 !important;
//   background-color: transparent !important;
//   width: 100% !important;
// }

.orderTable,.stockTable,.addUser,.productTable{
  width: 100% !important;
  height: 40vh !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  // padding: 100px;
}
.productTable{
  height: 50vh !important;
}
.orderTable{
  height: 55vh !important;

}
.stockTable{
  height: 40vh !important;

}
.addUser{
  width: 100% !important;
  height: 800px !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.loginCheck{
  width: 16px !important;
  height: 16px !important;
}
.loginCheckLabel{
  font-size: 16px !important;
  color: #f1f1f1;
}

.privacyLinkonLogin{
  text-decoration: none;
  color: #f1f1f1;
  
  &:hover{
    color: #111;
  }
}



@media(max-width:770px){
  .clientDashboard{
    .dashboard-actions{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      .btn{
        padding: 10px !important;
      }
    }
  }
}

@media(max-width:330px){
  .clientDashboard{
    .dashboard-actions{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      .btn{
        padding: 10px !important;
      }
      i{
        font-size: 2rem !important;
      }
    }
    
  }
  
}

.iconOnCustomerSide{
  width: 50px !important;
  height: 50px !important;
  margin-left: 10px;
  margin-top: -15px;
}

.table{
  .thead-dark th{
    border: none !important;
    background-color: $table-header-background-color !important;
    color: $table-header-color !important;
  }
  .thead-dark tr th button {
    color: $table-header-color !important;

  }
}

.mainProductImage{
  height:400px !important;width:400px;
}

.sliderForProductImage{
  height:400px !important;
  overflow: scroll;
  overflow-x: hidden;
  .slidingImage{
    height:100px;
    width:100px;
    margin:8px 0px;
    object-fit: cover;
    cursor: pointer;
  }
}
.sliderForProductImage::-webkit-scrollbar {
  width: 1px;
}


@media(max-width:770px){
  .mainProductImage{
    height:600px !important;width:650px;
  }
  .sliderForProductImage{
    height:100px !important;
    display:flex;
    overflow-x: scroll;
    .slidingImage{
      margin-right: 100px !important;
      height:70px;
    width:70px;
      
    }
  }
  .sliderForProductImage::-webkit-scrollbar {
    height: 4px;
  }
}